const flashes = Array.from(document.getElementsByClassName('flash_message'));
if (flashes.length > 0) {
    flashes.forEach((element) => {
        const seconds = element.dataset.seconds;

        setTimeout(function () {
            $(element).fadeToggle();
        }, seconds);
    });
}

